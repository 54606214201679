<template>
    <v-container fluid justify-center class="text-xs-center pt-5">
        <span class="title">{{ message }}</span>&nbsp;&nbsp;
        <v-progress-circular v-show="checking" size=20 color="info" indeterminate></v-progress-circular><br>
        <v-btn large round color="primary" to="/">{{ $t('emailAuth.enter-B-Box') }}</v-btn>
    </v-container>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { getAxiosConfig, decrypt, PWA_TOKEN } from '@/utils/AuthService'
import { HostUrl } from '@/store'
import i18n from '@/i18n'

export default {
    name: 'EmailAuth',
    data() {
        return {
            checking: false,
            message: i18n.t('emailAuth.check-code')
        }
    },
    methods: {
        checkAuth() {
            this.checking = true
            try {
                if (!this.$route.params.data) {
                    this.message = i18n.t('emailAuth.verification-blank')
                    this.checking = false
                    return
                } else {
                    let payload = JSON.parse(decrypt(this.$route.params.data))
                    console.log('payload', payload)
                    let self = this
                    let url = HostUrl + '/api/user/confirmauth'
                    axios.post(url, payload, getAxiosConfig(PWA_TOKEN))
                        .then(function (response) {
                            if (response.data.code === 0) {
                                store.commit('setUserId', response.data.profile.id)
                                store.dispatch('changeUser', {
                                    profile: response.data.profile,
                                    token: response.data.token
                                })
                                self.message = i18n.t('emailAuth.verification-completed')
                            } else {
                                store.commit('setUserToken', '')
                                self.message = response.data.message
                            }
                        })
                        .catch(function (error) {
                            console.log('check Auth API failed', error)
                            self.message = i18n.t('emailAuth.verification-fail')
                        })
                        .finally(function () {
                            self.checking = false
                        })
                }
            } catch (error) {
                console.log('checkAuth Failed', error)
                this.message = i18n.t('emailAuth.verification-fail')
                this.checking = false
            }
        },
        dialogTimeout() {
            this.message = i18n.t('emailAuth.exceed-time')
        }
    },
    activated() {
        this.checking = false
        this.message = i18n.t('emailAuth.check-code')
        this.checkAuth()
    }
}
</script>